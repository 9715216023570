
.container {
  background: #FFFFFF;
  border: 1px solid #D0D8E0;
  box-shadow: 0 13px 34px 0 rgba(71,69,120,0.06), 0 32px 65px 0 rgba(71,69,120,0.08), inset 0 -1px 0 0 #E5ECF3;
  border-radius: 5px;
  width: 60vw;
  height: 400px;
  max-width: 960px;
  min-width: 800px;
}

.header {
  padding: 40px 20px 0;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #373F49;
}

.logo {
  margin-right: -20px;
}

.sections {
  padding: 40px;
  display: flex;
  justify-content: space-evenly;
}

.section {
  text-align: center;
  color: #373F49;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  flex: 1 0 10px;
  margin: 0 auto;

  &:hover,
  &:focus {
    color: #4A90E2;
    .image,
    .imageSvg {
      border: 1px solid #4A90E2;
    }
  }
}
.image,
.imageSvg {
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 220px;
  border: 1px solid #D0D8E0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 220px;
    width: 100%;
    height: 157px;
    border-radius: 4px;
  }
}
.imageSvg {
  padding: 8px;
  img {
    height: 151px;
  }
}